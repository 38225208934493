<template>
  <router-view :school="school" :user="user"/>
</template>

<script>
import {destroyToken, getSchool, getUser, saveSchool, saveUser} from "@/core/services/jwt.service";
import {changeFavicon, stripTags} from "@/core/services/utils.service";
import ApiService from "@/core/services/api.service";

export default {
  name: "App",
  data() {
    return {
      user: null,
      school: {
        name: "Erobo",
        thumbnail: {
          link: "/assets/images/brand/logo/erobo.jpg"
        }
      }
    }
  },
  mounted() {
    this.fetchUser();
    this.fetchSchool();
    this.handleAppHeight();
    this.handleUpdateMe();
  },
  methods: {
    getMe() {
      this.user = null;

      let query = `query {
        me {
          id
          uid
          name
          email
          phone
          role
          school_role
          agency_role
          thumbnail {
            link
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.me) {
              this.user = data.data.me;
              this.user.last_fetch = Math.floor(Date.now() / 1000);
              saveUser(this.user);
            } else {
              destroyToken();
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    getSchool() {
      let query = `query {
        theSchool {
          code
          name
          content
          thumbnail {
            link
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.theSchool) {
              this.school = data.data.theSchool;
              this.school.last_fetch = Math.floor(Date.now() / 1000);
              saveSchool(this.school);
            }
          });
    },
    changeHTMLTags() {
      if (this.school) {
        this.school.content = stripTags(this.school.content);
        if (this.school.thumbnail) {
          changeFavicon(this.school.thumbnail.link);
          document.querySelector('meta[property="og:image"]').setAttribute("content", this.school.thumbnail.link);
        }
        document.title = this.school.name;
        document.querySelector('meta[name="description"]').setAttribute("content", this.school.content);
        document.querySelector('meta[property="og:description"]').setAttribute("content", this.school.content);
        document.querySelector('meta[property="og:title"]').setAttribute("content", this.school.name);
        document.querySelector('meta[property="og:url"]').setAttribute("content", window.location.origin);
      }
    },
    fetchUser() {
      let user = getUser();

      if (!user || user.last_fetch < (Math.floor(Date.now() / 1000) - 86400)) {
        this.getMe();
      } else {
        this.user = user;
      }
    },
    fetchSchool() {
      let school = getSchool();

      if (!school || school.last_fetch < (Math.floor(Date.now() / 1000) - 86400)) {
        this.getSchool();
      } else {
        this.school = school;
      }
    },
    handleUpdateMe() {
      window.addEventListener("message", (event) => {
        if (event.data.action === "updateMe") {
          this.getMe();
        }
      }, false);
    },
    handleAppHeight() {
      const appHeight = () => {
        const doc = document.documentElement;
        doc.style.setProperty('--app-height', `${window.innerHeight}px`);
      }
      window.addEventListener('resize', appHeight);
      appHeight();
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title ? this.school.name + " - " + to.meta.title : this.school.name;
      }
    },
    school: {
      handler() {
        this.changeHTMLTags();
      },
      deep: true
    }
  }
}
</script>
