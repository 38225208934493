import {getUser} from "@/core/services/jwt.service";
import { format, register } from 'timeago.js';

const localeFunc = (number, index) => {
  return [
    ['vừa xong', 'right now'],
    ['%s giây trước', 'in %s seconds'],
    ['1 phút trước', 'in 1 minute'],
    ['%s phút trước', 'in %s minutes'],
    ['1 giờ trước', 'in 1 hour'],
    ['%s giờ trước', 'in %s hours'],
    ['1 ngày trước', 'in 1 day'],
    ['%s ngày trước', 'in %s days'],
    ['1 tuần trước', 'in 1 week'],
    ['%s tuần trước', 'in %s weeks'],
    ['1 tháng trước', 'in 1 month'],
    ['%s tháng trước', 'in %s months'],
    ['1 năm trước', 'in 1 year'],
    ['%s năm trước', 'in %s years']
  ][index];
}

register('vi_VN', localeFunc);

export const getWhereCondition = (filters) => {
  let where = "";
  let conditions = [];

  for (let key in filters) {
    let item = filters[key];
    conditions.push('{column: "' + key + '", value: "' + item.value + '", operator: ' + item.operator + '}');
  }

  if (conditions.length) {
    where = where + ",  where: {AND: [";
    where = where + conditions.join(", ");
    where = where + "]}";
  }

  return where;
};

export const getWhereCondition2 = (filters) => {
  let where = "";
  let conditions = [];

  for (let key in filters) {
    let item = filters[key];
    conditions.push('{column: "' + key + '", value: "' + item.value + '", operator: ' + item.operator + '}');
  }

  if (conditions.length) {
    where = where + "{AND: [";
    where = where + conditions.join(", ");
    where = where + "]}";
  }

  return where;
};

export const cutString = (str, len = 10) => {
  if (str.length <= len) {
    return str;
  }

  let tmp = str.split(".");
  if (tmp.length > 1) {
    let ext = tmp[tmp.length - 1];
    return str.substring(0, len) + '...' + ext;
  }

  return str.substring(0, len) + '...';
};

export const changeFavicon = link => {
  let $favicon = document.querySelector('link[rel="icon"]')
  if ($favicon !== null) {
    $favicon.href = link
  } else {
    $favicon = document.createElement("link")
    $favicon.rel = "icon"
    $favicon.href = link
    document.head.appendChild($favicon)
  }
}

export const openModal = id => {
  window.$("#" + id + "Open").click();
}

export const closeModal = id => {
  window.$("#" + id + "Close").click();
}

export const getSectionLessonCount = course => {
  let sectionCount = 0;
  let lessonCount = 0;

  if (course.sections && course.sections.length) {
    for (let i = 0 ; i < course.sections.length; i++) {
      sectionCount++;
      if (course.sections[i].lessons && course.sections[i].lessons.length) {
        for (let j = 0; j < course.sections[i].lessons.length; j++) {
          lessonCount++;
        }
      }
    }
  }

  return {
    lessonCount: lessonCount,
    sectionCount: sectionCount
  }
}

export const isMe = user => {
  let me = getUser();
  return (me && user.uid === me.uid)
}

export const convertOembed = content => {
  const oembed = content.split('</oembed>');
  let body = '';
  oembed.forEach((item, index) => {
    body += oembed[index] + '</oembed>';
    const oembed1 = item.split('url="')[1];
    if (oembed1) {
      const oembed2 = oembed1.split('">')[0];
      if (oembed2) {
        const youtube = oembed2.split('https://www.youtube.com/watch?v=')[1];
        if (youtube) {
          body += '<div class="iframe-container"><iframe width="748" height="421" src="https://www.youtube.com/embed/' + youtube + '" frameborder="0"; scrolling="no"; allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>';
        } else {
          let src = oembed2.replace(/https:\/\/erobo.vn/, window.location.origin);
          body += '<div class="iframe-container"><iframe width="748" height="421" src="' + src + '"></iframe></div>';
        }
      }
    }
  });
  return body;
}

export const stripTags = content => {
  return content ? content.replace(/(<([^>]+)>)/gi, "") : "";
}

export const updateMe = from => {
  window.postMessage({action: "updateMe", from: from},
    window.location.origin);
}

export const taskStatuses = [
  {name: "Chờ tiếp nhận", status: "waiting_for_received", class: "warning"},
  {name: "Đã tiếp nhận", status: "received", class: "secondary"},
  {name: "Đang xử lý", status: "processing", class: "info"},
  {name: "Đã hoàn thành", status: "completed", class: "success"}
];

export const getTaskStatusName = status => {
  for (let i = 0; i < taskStatuses.length; i++) {
    if (taskStatuses[i].status === status) {
      return taskStatuses[i].name;
    }
  }

  return "";
}

export const getTaskStatusClass = status => {
  for (let i = 0; i < taskStatuses.length; i++) {
    if (taskStatuses[i].status === status) {
      return taskStatuses[i].class;
    }
  }

  return "";
}

export const timeago = date => {
  return format(date, 'vi_VN');
}

export const isPageAdmin = () => {
  let tmp = window.location.hostname.split(".");
  return tmp[0] === "5seschool" || tmp[0] === "localhost" || (tmp.length === 3 && tmp[0] === "master");
}

export const isPageAgency = () => {
  let tmp = window.location.hostname.split(".");
  return tmp[0] === "5seschool" || tmp[0] === "localhost" || (tmp.length === 3 && tmp[0] === "agency");
}

export const getSchoolDomain = (subdomain) => {
  let home_url = process.env.VUE_APP_HOME_URL;
  return home_url.replace("*", subdomain);
}

export const getCookie = (name) => {
  const regex = new RegExp(`(^| )${name}=([^;]+)`);
  const match = document.cookie.match(regex);
  if (match) {
    return match[2];
  }
}

export default {cutString, getWhereCondition, changeFavicon, openModal, closeModal, getSectionLessonCount, stripTags};
