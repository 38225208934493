import axios from "axios";
import {getToken} from "@/core/services/jwt.service";
import {getCookie} from "@/core/services/utils.service";

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL
});

axiosInstance.interceptors.request.use(function (config) {
  const token = getToken();
  config.headers.Authorization =  token ? `Bearer ${token}` : '';
  let params = (new URL(document.location)).searchParams;
  let subdomain = params.get("subdomain");
  if (subdomain) {
    config.headers.subdomain = subdomain;
  }
  
  return config;
});

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  get(resource, params) {
    return axiosInstance.get(resource, {params: params}).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return axiosInstance.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return axiosInstance.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return axiosInstance.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return axiosInstance.delete(resource).catch(error => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @returns {*}
   * @param query
   * @param variables
   */
  graphql(query, variables) {
    let url = 'graphql';
    let xdebug = getCookie("XDEBUG_SESSION");
    if (xdebug) {
      url = url + "?XDEBUG_SESSION_START=1";
    }
    return axiosInstance.post(url, {
      query: query,
      variables: variables
    });
  },

  upload(file, config = {}) {
    let formData = new FormData();

    formData.append(
      "operations",
      `{ "query": "mutation ($file: Upload) { upload(file: $file) {id,name,link,media_type,extension,size} }", "variables": { "file": null } }`
    );
    formData.append(
      "map",
      '{ "0": ["variables.file"] }'
    );
    formData.append(
      "0",
      file
    );

    config.headers = {
      "Content-Type": "multipart/form-data"
    };

    return axiosInstance.post(`graphql`, formData, config);
  }
};

export default ApiService;
