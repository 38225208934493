import { createRouter, createWebHistory } from 'vue-router'
import {isPageAdmin, isPageAgency} from "@/core/services/utils.service";

const routes = [
  {
    path: '/',
    component: () => import("@/components/Layout"),
    children: [
      {
        path: "/",
        name: "Home",
        component: () => import("@/components/Index")
      },
      {
        path: "/admin",
        name: "Admin",
        component: () => (isPageAdmin() || isPageAgency()) ? import("@/components/admin/agency/detail") : import("@/components/Index")
      },
      {
        path: "/notifications",
        name: "Notification",
        component: () => import("@/components/Notification")
      },
      {
        path: "/my-agency",
        name: "MyAgency",
        component: () => import("@/components/admin/agency/detail")
      },
      {
        path: "/admin/agency/add",
        name: "AdminAgencyAdd",
        component: () => import("@/components/admin/agency/add")
      },
      {
        path: "/admin/agency/:code",
        name: "AdminAgencyDetail",
        component: () => import("@/components/admin/agency/detail")
      },
      {
        path: "/admin/agency/edit/:code",
        name: "AdminAgencyEdit",
        component: () => import("@/components/admin/agency/edit")
      },
      {
        path: "/admin/agency",
        name: "AdminAgencyList",
        component: () => import("@/components/admin/agency/index")
      },
      {
        path: "/admin/task/:code",
        name: "AdminTaskDetail",
        component: () => import("@/components/admin/task/detail")
      },
      {
        path: "/admin/course/add",
        name: "AdminCourseAdd",
        component: () => import("@/components/admin/course/add")
      },
      {
        path: "/admin/course/edit/:code",
        name: "AdminCourseEdit",
        component: () => import("@/components/admin/course/edit")
      },
      {
        path: "/admin/course/detail/:code",
        name: "AdminCourseDetail",
        component: () => import("@/components/admin/course/detail")
      },
      {
        path: "/admin/school/add",
        name: "AdminAddSchool",
        component: () => import("@/components/admin/school/add")
      },
      {
        path: "/admin/school/edit/:code",
        name: "AdminSchoolEdit",
        component: () => import("@/components/admin/school/edit")
      },
      {
        path: "/admin/school/detail/:code",
        name: "AdminSchoolDetail",
        component: () => import("@/components/admin/school/detail")
      },
      {
        path: "/admin/user/add",
        name: "AdminAddUser",
        component: () => import("@/components/admin/user/add")
      },
      {
        path: "/admin/user/edit/:uid",
        name: "AdminEditUser",
        component: () => import("@/components/admin/user/edit")
      },
      {
        path: "/admin/user/detail/:uid",
        name: "AdminUserDetail",
        component: () => import("@/components/user/detail")
      },
      {
        path: "/admin/user",
        name: "AdminListUser",
        component: () => import("@/components/admin/user/index")
      },
      {
        path: "/admin/school",
        name: "AdminListSchool",
        meta: {title: "Quản lý trường học"},
        component: () => import("@/components/admin/school/index")
      },
      {
        path: "/admin/page/add",
        name: "AdminPageAdd",
        component: () => import("@/components/admin/page/add")
      },
      {
        path: "/admin/page/edit/:code",
        name: "AdminPageEdit",
        component: () => import("@/components/admin/page/edit")
      },
      {
        path: "/admin/combo/add",
        name: "AdminComboAdd",
        component: () => import("@/components/admin/combo/add")
      },
      {
        path: "/admin/combo/edit/:code",
        name: "AdminComboEdit",
        component: () => import("@/components/admin/combo/edit")
      },
      {
        path: "/admin/combo/detail/:code",
        name: "AdminComboDetail",
        component: () => import("@/components/admin/combo/detail")
      },
      {
        path: "/combo/detail/:code",
        name: "ComboDetail",
        component: () => import("@/components/combo/detail")
      },
      {
        path: "/course/detail/:code",
        name: "CourseDetail",
        component: () => import("@/components/course/detail")
      },
      {
        path: "/:slug.:code.html",
        name: "PageDetail",
        component: () => import("@/components/page/detail")
      },
      {
        path: "/tin-tuc",
        name: "PageList",
        component: () => import("@/components/page/index")
      },
      {
        path: "/my-school",
        name: "MySchool",
        component: () => import("@/components/admin/school/detail")
      },
      {
        path: "/my-account",
        name: "MyAccount",
        component: () => import("@/components/user/detail")
      },
      {
        path: "/:catchAll(.*)",
        component: () => import("@/components/404")
      }
    ]
  },
  {
    path: '/',
    component: () => import("@/components/LayoutNoHeaderFooter"),
    children: [
      {
        path: "/course/view/:code",
        name: "ViewCourse",
        component: () => import("@/components/course/view")
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import("@/components/Login"),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import("@/components/Signup"),
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import("@/components/Logout"),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import("@/components/ForgotPassword"),
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: () => import("@/components/ChangePassword"),
  },
  {
    path: '/validate',
    name: 'ValidateCode',
    component: () => import("@/components/ValidateCode"),
  },
  {
    path: "/embed/:uid",
    name: "Embed",
    component: () => import("@/components/Embed")
  }
]

const router = createRouter({
  history: createWebHistory("/"),
  routes
})

export default router
