import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import DropZone from 'dropzone-vue';
import 'dropzone-vue/dist/dropzone-vue.common.css';

window.$ = window.jQuery = require('jquery');

const app = createApp(App);

app.use(router, DropZone).mount('#app')

