const ID_TOKEN_KEY = "access_token";
const ID_USER_KEY = "user";
const ID_SCHOOL_KEY = "school";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const destroyUser = () => {
  window.localStorage.removeItem(ID_USER_KEY);
};

export const saveUser = user => {
  window.localStorage.setItem(ID_USER_KEY, JSON.stringify(user));
};

export const updateUser = (key, value) => {
  let user = getUser();
  if (!user) {
    user = {};
  }
  user[key] = value;
  saveUser(user);
};


export const saveSchool = school => {
  window.localStorage.setItem(ID_SCHOOL_KEY, JSON.stringify(school));
};

export const getUser = () => {
  try {
    return JSON.parse(window.localStorage.getItem(ID_USER_KEY));
  } catch (e) {
    return null;
  }
};

export const getSchool = () => {
  try {
    return JSON.parse(window.localStorage.getItem(ID_SCHOOL_KEY));
  } catch (e) {
    return null;
  }
};

export const destroySchool = () => {
  window.localStorage.removeItem(ID_SCHOOL_KEY);
};

export default { getToken, saveToken, destroyToken, getUser, saveUser };
